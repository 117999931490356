<template>
  <div class="page">
    <ViewGroup />
    <!-- <List el="xsm">
      <div class="google-btn">Sign In</div>
    </List> -->
  </div>
</template>

<script>
import ViewGroup from "@/components/ViewGroup.vue";
// import Icon from "@/components/btn/Icon.vue";
// import {
// Layout,
// Header,
// Btn,
// List,
// Accordion,
// Input,
// Text,
// Link,
// Modal,
// Alert,
// } from "@/design-system";

export default {
  name: "Home",
  components: {
    ViewGroup,
    // Icon,
    // Layout,
    // Header,
    // Btn,
    // List,
    // Accordion,
    // Input,
    // Text,
    // Link,
    // Modal,
    // Alert,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.page {
  // max-width: 400px;
  // margin: auto;
  height: 100%;
}
.google-btn {
  height: 50px;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 10px;
  padding: 0 20px;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  color: hsla(0, 0%, 100%, 0.87);
  font-weight: 500;
  text-align: center;
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: 16px center;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
}
.google-btn:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
  color: white;
  cursor: pointer;
}
</style>
