<template>
  <List el="xxsm" class="content" v-if="hide">
    <div></div>
    <List el="xxsm" class="current">
      <Text el="h3">{{ activeGroup.name }}</Text>
      <Text el="h1">{{ relativeTime }}</Text>
    </List>
    <div></div>
  </List>
  <List el="xxsm" class="content" v-else>
    <!-- <Text>{{ relativeStartTime }}</Text> -->
    <List el="xxsm" v-if="sections.prev != null" class="prev">
      <Text style="font-size:20px;">Prev: {{ sections.prev.name }}</Text>
      <Text el="bold">{{ Math.round(sections.prev.duration) }} mins</Text>
    </List>
    <div v-else></div>
    <List el="xxsm" v-if="sections.current != null" class="current">
      <Text el="h1" :class="{ pulse: countdown.past }">{{
        sections.current.name
      }}</Text>
      <Text el="h1" id="timer"
        >{{ countdown.past ? "-" : "" }}{{ countdown.mins }}:{{
          countdown.sec
        }}</Text
      >
    </List>
    <List el="column-between xxsm" v-if="sections.next != null" class="next">
      <List el="xxsm" v-if="sections.next != null">
        <Text style="font-size:20px;">Next: {{ sections.next.name }}</Text>
        <Text el="bold">{{ Math.round(sections.next.duration) }} mins</Text>
      </List>
      <Icon
        name="east"
        style="font-size:50px;"
        v-if="!activeGroup.autoAdvance"
        @click="nextSection"
      />
    </List>
    <div v-else style="padding-bottom:15px;">
      <table el="table">
        <thead>
          <tr>
            <th>Section</th>
            <th>Mins</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in activeGroup.sections" :key="item">
            <td>{{ item.name }}</td>
            <td>{{ Math.round(item.duration) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </List>
</template>
<script>
import Icon from "@/components/btn/Icon.vue";
const dayjs = require("dayjs");
let customParseFormat = require("dayjs/plugin/customParseFormat");
let relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
import {
  // Layout,
  // Header,
  // Btn,
  List,
  // Accordion,
  // Input,
  Text,
  // Link,
  // Modal,
  // Alert,
} from "@/design-system";

export default {
  name: "Home",
  props: ["group"],
  components: {
    Icon,
    // Layout,
    // Header,
    // Btn,
    List,
    // Accordion,
    // Input,
    Text,
    // Link,
    // Modal,
    // Alert,
  },
  data() {
    return {
      groups: this.$store.state.groups,
      sections: this.$store.state.sections,
      countdown: this.$store.state.countdown,
      hide: true,
      relativeTime: null,
      screenLock: this.$store.state.screenLock,
    };
  },
  beforeUnmount() {
    clearTimeout(this.countdown.timeout);
    clearInterval(this.countdown.timer);
    if (!this.activeGroup.autoAdvance) {
      console.log("manual save index!");
      // localStorage.currentIndex = {this.sections.currentIndex};
    }
  },
  watch: {
    "sections.current": function() {
      let x = this;
      x.$store.state.layout.activeTransition = true;
      setTimeout(() => {
        x.$store.state.layout.activeTransition = false;
      }, 500);
    },
  },
  methods: {
    init() {
      let x = this;
      let diff = this.getStartDiff();
      let start = dayjs(this.activeGroup.startTime, "H:mm");
      x.relativeTime = start.fromNow();
      clearTimeout(x.countdown.timeout);
      if (diff < 0) {
        this.hide = false;
        this.setupSectionTime();
        this.setCurrentSection();
        this.setPrevSection();
        this.setNextSection();
        if (this.sections.current != null) {
          this.startCountdown();
        }
      } else {
        this.hide = true;
        // set timer and rerun init
        x.countdown.timeout = setTimeout(() => {
          let start = dayjs(this.activeGroup.startTime, "H:mm");
          x.relativeTime = start.fromNow();
          x.init();
          // console.log("Run init: " + Date.now());
        }, 1000);
      }
    },
    startCountdown() {
      let x = this;
      x.setCountdown();
      clearInterval(x.countdown.timer);
      x.countdown.timer = setInterval(() => {
        x.setCountdown();
        // console.log("Countdown: " + Date.now());
      }, 1000);
    },
    setCountdown() {
      let x = this;
      const end = dayjs(x.sections.current.end, "H:mm");
      const now = dayjs();
      let diff = end.diff(now) / 1000;
      if (diff < 0) {
        diff = Math.abs(diff);
        x.countdown.past = true;
      } else {
        x.countdown.past = false;
      }
      const mins = Math.floor(diff / 60);
      const sec = Math.floor(diff - mins * 60);
      x.countdown.mins = mins;
      x.countdown.sec = sec < 10 ? `0${sec}` : sec;
      if (x.countdown.past && x.activeGroup.autoAdvance) {
        x.setCurrentSection();
        x.setPrevSection();
        x.setNextSection();
      }
    },
    getStartDiff() {
      const now = dayjs();
      const start = dayjs(this.activeGroup.startTime, "H:mm");
      return start.diff(now);
    },
    getEndDiff() {
      this.setupSectionTime();
      const now = dayjs();
      const end = dayjs(
        this.activeGroup.sections[this.activeGroup.sections.length - 1],
        "H:mm"
      );
      return end.diff(now);
    },
    setupSectionTime() {
      let start = dayjs(this.activeGroup.startTime, "H:mm");
      this.activeGroup.sections.forEach((item) => {
        // item.start = start.format("H:mm");
        start = start.add(item.duration, "minute");
        item.end = start.format("H:mm");
        item.endedAt = start.format("H:mm");
      });
      console.log(this.activeGroup.sections);
    },
    formatTime(time) {
      return dayjs(time, "H:mm").format("h:mma");
    },
    setCurrentSection() {
      let x = this;
      const now = dayjs().valueOf();
      this.sections.current = this.activeGroup.sections.find((item, index) => {
        let end = dayjs(item.end, "H:mm").valueOf();
        if (now - end < 0) {
          x.sections.currentIndex = index;
        }
        return now - end < 0;
      });
      console.log("Current: ", this.sections.current);
    },
    nextSection() {
      let x = this;

      // find total remaining time
      let now = dayjs(),
        end = dayjs(
          this.activeGroup.sections[this.activeGroup.sections.length - 1].end,
          "H:mm"
        ),
        timeDiff = end.diff(now) / 60000;

      // find total remaining duration
      let totalMinDur = 0;
      this.activeGroup.sections.forEach((item, index) => {
        if (index > x.sections.currentIndex) {
          totalMinDur += item.duration;
        }
      });
      // assign new duration
      let currentDur = 0;
      this.activeGroup.sections.forEach((item, index) => {
        if (index === x.sections.currentIndex) {
          item.endedAt = now.format("H:mm");
          let currentEnd = dayjs(item.end, "H:mm"),
            endDiff = now.diff(currentEnd) / 60000;
          console.log(endDiff);
          item.duration += endDiff;
        }
        if (index > x.sections.currentIndex) {
          item.duration = timeDiff * (item.duration / totalMinDur);
          currentDur += item.duration;
          let newEnd = now.add(currentDur, "minute");
          item.end = newEnd.format("H:mm");
        }
      });

      console.log(this.activeGroup.sections);

      x.sections.currentIndex = x.sections.currentIndex + 1;
      x.sections.current = x.activeGroup.sections[x.sections.currentIndex];

      x.setPrevSection();
      x.setNextSection();
    },
    setPrevSection() {
      if (this.sections.currentIndex != 0) {
        this.sections.prev = this.activeGroup.sections[
          this.sections.currentIndex - 1
        ];
      } else {
        this.sections.prev = null;
      }
      console.log("Prev: ", this.sections.prev);
    },
    setNextSection() {
      if (this.sections.currentIndex != this.activeGroup.sections.length - 1) {
        this.sections.next = this.activeGroup.sections[
          this.sections.currentIndex + 1
        ];
      } else {
        this.sections.next = null;
      }
      console.log("Next: ", this.sections.next);
    },
  },
  computed: {
    activeGroup() {
      return this.groups.list.find((item) => item.id === this.groups.active);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
}
.group-list {
  padding: 0 12px;
  max-width: 400px;
  margin: auto;
}
.group-summary {
  user-select: none;
}
.group {
  --bg-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 10px;
  padding: 15px;
  transition: 0.2s;
}
.group:hover
// .group.active 
 {
  background-color: hsla(0, 0%, 100%, 0.2);
  color: white;
  cursor: pointer;
}
.prev {
  --bg-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 0px 30px 30px 0;
  padding: 30px 20px;
  transition: 0.2s;
  max-width: 400px;
  margin-right: 60px;
}
.current {
  align-self: center;
  text-align: center;
  padding: 15px;
}
.next {
  --bg-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 30px 30px 0px 0;
  padding: 30px 15px;
  transition: 0.2s;
  max-width: 600px;
  justify-self: end;
  width: 100%;
  align-items: center;
}
.next:hover {
  --bg-color: hsla(0, 0%, 100%, 0.1);
}
#timer {
  font-size: 80px;
}
.pulse {
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
table {
  max-width: 400px;
  width: calc(100% - 30px);
  margin: auto;
}
.screenLock {
  padding: 15px;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
